import React from 'react';
import styled from 'styled-components';
import SEO from '../../components/seo';
import Layout from '../../components/Layout';

const Title = styled.h1`
  font-size: 40px;
`;

const Image = styled.img`
  max-height: 400px;
  display: block;
`;

const Copyright = styled.span`
  text-align: right;
  display: block;
  font-size: 13px;
  color: white;
  margin: 12px 0 32px;
`;

const Wrapper = styled.div`
  max-width: 720px;
  padding: 0 22px;
`;

const Subtitle = styled.h2`
  display: block;
  color: white;
  font-size: 30px;
  margin-top: 32px;
`;

const P = styled.p`
  color: white;
  font-size: 18px;
`;

const UL = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const LI = styled.li`
  color: white;
  margin: 52px 0;

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 20px;
  }
`;

function BrianErEnNar() {
  return (
    <>
      <SEO
        title="Oplev Brian Mørk med hans show Brian er en nar d. 20 maj 2022"
        description=""
      />
      <Layout>
        <Wrapper className="event">
          <Title className="title">
            Oplev Brian Mørk og hans show Brian er en nar!
          </Title>
          <Image
            className="event__image"
            src="https://www.billetlugen.dk/obj/media/DK-eventim/teaser/222x222/2021/brian_er_en_nar_222x222.jpg"
            alt=""
          />
          <Copyright>Billede fra Billetlugen</Copyright>
          <Subtitle>
            Tag til Aalborg og oplev Brian er en nar hos Event Aalborg d. 20 maj
            2022
          </Subtitle>
          <P className="event__info_explore">
            Der bliver ikke lagt låg på når Brian går på scenen i sit andet
            one-man show BRIAN ER EN NAR. For Brian Mørk er tilbage og han er
            næsten lige så vred som han er sjov. Træt af at folk vil bestemme
            hvad han ikke må gøre grin med eller udtale sig om, tager han tidens
            hykleri, skyttegravstænkning og udskamnings-kultur under kærlig
            behandling. Han er klar til at tage fløjlshandskerne af, brænde
            broer og risikere at træde på dine følelser. Så hvis du er typen der
            har brug for en trigger warning, så får du en nu: Bliv hjemme og lad
            de voksne se stand-up.
            <br />
            <br />
            Brian bragte os de populære Mørk & Jul og Brian Mørk Show på TV2
            Zulu og C-more. Han skrev comedy klassikere som Dolph & Wulff, Live
            Fra Bremen, Gustne Gensyn og utallige andre. Han er manden og værten
            bag en af Danmarks absolut populæreste og længst kørende comedy
            podcasts og han er enlig far til tre børn der er klogere og kønnere
            end ham.
          </P>
          <a
            style={{ maxWidth: 220 }}
            className="btn event__link info__buyticket-link onsale"
            href="https://www.billetlugen.dk/event/brian-moerk-brian-er-en-nar-event-aalborg-14125277/?affiliate=MR4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Køb billet</span>
          </a>
        </Wrapper>
      </Layout>
    </>
  );
}

export default BrianErEnNar;
